<template>
  <div class="grading">
    <a-modal
      v-if="sesiGradings.dataAG"
      centered
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" />
        UPDATE {{ sesiGradings.dataAG.kela.tingkat }} -
        {{ sesiGradings.dataAG.kela.simbol }} GRADING
      </h3>
      <p>
        Are you sure to update class {{ sesiGradings.dataAG.kela.tingkat }} -
        {{ sesiGradings.dataAG.kela.simbol }}'s grading? Your data before will
        be lost once you update.
      </p>
    </a-modal>
    <a-modal
      v-if="sesiGradings.dataAG"
      :visible="modalAssignmentVisible"
      title="Assignment Details"
      centered
      @cancel="closeModalAssignment"
    >
      <!-- <a-divider orientation="left">
        <p class="font-weight-bold">Details</p>
      </a-divider> -->
      <detail-assignment :assignment="sesiGradings.dataAG.sesis[0]" />
      <template slot="footer">
        <a-button class="d-none" key="back"></a-button>
        <a-button class="d-none" key="submit"></a-button>
      </template>
    </a-modal>
    <div class="coverDetail" v-if="sesiGradings.dataAG">
      <!-- {{ sesiGradings.dataAG.sesis[0] }} -->
      <h2>
        {{ sesiGradings.dataAG.kela.tingkat }} -
        {{ sesiGradings.dataAG.kela.simbol }} |
        {{ sesiGradings.dataAG.mata_pelajaran.nama }}
      </h2>
      <div>
        Session {{ sesiGradings.dataAG.sesis[0].week.week }}/{{
          sesiGradings.totalWeek
        }}
        | {{ sesiGradings.dataAG.sesis[0].topik.subtopik }}
      </div>
    </div>
    <div class="contentDetail" v-if="sesiGradings.dataAG">
      <div class="detail-slot">
        <a-row type="flex" justify="space-between" class="mb-5">
          <a-col :xs="24" :md="12" :lg="6">
            <h3 class="type">Type</h3>
            <div class="d-flex mt-2 align-items-end">
              <div>
                <p class="value text-primary mr-2">
                  <a-icon :type="tipeAssignment.icon" class="mr-2" />
                  {{ tipeAssignment.type }}
                </p>
              </div>
              <div>
                <a-button
                  @click.prevent="toAssignment"
                  shape="round"
                  size="small"
                  icon="eye"
                ></a-button>
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6">
            <h3 class="type">Due Date</h3>
            <p
              class="value mt-2"
              :style="{
                color:
                  dueDate(this.sesiGradings.dataAG.sesis[0].tugas_deadline2) ===
                  '-'
                    ? '#041930'
                    : '#FF0000',
              }"
            >
              {{ dueDate(this.sesiGradings.dataAG.sesis[0].tugas_deadline2) }}
            </p>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Waiting to Submit</h3>
            <p class="value mt-2">
              <span class="font-weight-bold text-danger">{{
                sesiGradings.waiting_to_submit
              }}</span>
              / {{ sesiGradings.students }} Student
            </p>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Graded</h3>
            <p class="value mt-2">
              <span class="font-weight-bold text-success">{{
                sesiGradings.gradeSubmit[0].graded
              }}</span>
              / {{ sesiGradings.gradeSubmit[0].submitted }} Submitted
            </p>
          </a-col>
        </a-row>
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="w-100 w-lg-75">
            <a-divider orientation="left">
              <h3>Assignment Grading</h3>
            </a-divider>
          </div>
          <div class="w-auto d-flex flex-column flex-md-row ml-3">
            <template v-if="!isEdit">
              <a-button
                shape="round"
                icon="select"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >SYNC</a-button
              >
              <a-button
                @click.prevent="
                  () => {
                    isEdit = true;
                  }
                "
                shape="round"
                type="primary"
                icon="edit"
                >INPUT GRADE</a-button
              >
            </template>
            <template v-else>
              <a-button
                @click.prevent="cancel()"
                :loading="loadingEditGrading"
                type="danger"
                shape="round"
                icon="close"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >CANCEL</a-button
              >
              <a-button
                @click.prevent="save()"
                shape="round"
                icon="save"
                style="background-color: #41b883; color: white"
                >SAVE</a-button
              >
            </template>
          </div>
        </div>
        <a-drawer
          class="drawer-grading"
          placement="left"
          :closable="true"
          :visible="visible"
          @close="onClose"
          title="Assignment Result"
        >
          <!-- <p>{{dataDrawer}}</p> -->
          <p>Name : {{ dataDrawer.Name }}</p>
          <a-divider />
          <p>Submit Status : {{ dataDrawer.SubmitStatus }}</p>
          <a-divider />
          <p>Feedback : {{ dataDrawer.Feedback }}</p>
          <a-divider />
          <!-- <p>Grade : {{dataDrawer.Grade}}</p> -->
          <p>
            Grade :
            <span v-if="dataDrawer.Grade >= 70 && dataDrawer.Grade <= 100">
              <a-tag color="green">{{ dataDrawer.Grade }}</a-tag>
            </span>
            <span v-if="dataDrawer.Grade >= 35 && dataDrawer.Grade <= 69">
              <a-tag color="orange">{{ dataDrawer.Grade }}</a-tag>
            </span>
            <span v-if="dataDrawer.Grade > 0 && dataDrawer.Grade <= 34">
              <a-tag color="red">{{ dataDrawer.Grade }}</a-tag>
            </span>
          </p>
          <a-divider />
          <p class="mb-3 font-weight-bold text-dark">Assignment Answer :</p>
          <div
            v-if="dataDrawer.TipeAssignment === 'File Upload'"
            class="d-flex justify-content-between align-items-center pb-3"
          >
            <div class="mr-auto">
              <p>{{ dataDrawer.AssignmentAnswer }}</p>
            </div>
            <div>
              <a-button
                @click="
                  downloadFile(
                    dataDrawer.AssignmentPath,
                    dataDrawer.AssignmentAnswer
                  )
                "
                type="primary"
                shape="round"
                icon="download"
                >DOWNLOAD</a-button
              >
            </div>
          </div>
          <div v-else-if="dataDrawer.TipeAssignment === 'Essay'">
            <span v-html="dataDrawer.AssignmentAnswer"></span>
          </div>
          <div v-else-if="dataDrawer.TipeAssignment === 'Multiple Choice'">
            <!-- {{dataDrawer.AssignmentDetail}} -->
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <p>Questions : {{ dataDrawer.AssignmentDetail.length }}</p>
              </div>
              <div>
                <p>Correct :
                {{
                  cekAnswer(
                    dataDrawer.AssignmentDetail,
                    dataDrawer.AssignmentAnswer
                  ).correct
                }}</p>
              </div>
              <div>
                <p>Incorrect :
                {{
                  cekAnswer(
                    dataDrawer.AssignmentDetail,
                    dataDrawer.AssignmentAnswer
                  ).inCorrect
                }}</p>
              </div>
              <div>
                <p>Not Answered :
                {{
                  cekAnswer(
                    dataDrawer.AssignmentDetail,
                    dataDrawer.AssignmentAnswer
                  ).notAnswer
                }}</p>
              </div>
            </div>
            <detail-assignment :assignment="sesiGradings.dataAG.sesis[0]" :isStudentChoice="true" :studentAnswer="dataDrawer.AssignmentAnswer" />
          </div>
        </a-drawer>

        <transition name="slide-fadein-up" mode="out-in">
          <a-table
            class="table-scroll mt-4 mt-lg-0"
            v-if="!isEdit"
            :columns="columns"
            :data-source="dataTable"
            :pagination="{ pageSize: 30, hideOnSinglePage: true }"
            bordered
          >
            <span slot="SubmitStatus" slot-scope="text, record">
              {{ text }}
              <a-button
                @click="showDrawer(record.idMurid)"
                shape="round"
                icon="eye"
                v-if="text != 'Waiting'"
              ></a-button>
            </span>

            <div
              slot="Notes"
              slot-scope="text, record"
              style="text-align: center; font-size: 1.5em"
            >
              <a-icon
                v-if="record.Notes && record.Notes !== ''"
                style="color: #41b883"
                type="check-circle"
              />
              <div v-else class="circleUndone" />
            </div>

            <span slot="Grade" slot-scope="text">
              <grade-color :grade="Number(text)" />
            </span>
          </a-table>

          <!-- IsEdit Real -->
          <a-table
            v-else
            class="table-scroll mt-4 mt-lg-0"
            :columns="columns"
            :data-source="dataTable"
            :pagination="{ pageSize: 30, hideOnSinglePage: true }"
            bordered
          >
            <span slot="SubmitStatus" slot-scope="text, record">
              {{ text }}
              <a-button
                @click="showDrawer(record.idMurid)"
                shape="round"
                icon="eye"
                v-if="text != 'Waiting'"
              ></a-button>
            </span>

            <div
              slot="Notes"
              slot-scope="text, record"
              style="text-align: center; font-size: 1.5em"
            >
              <a-icon
                v-if="record.Notes && record.Notes !== ''"
                style="color: #41b883"
                type="check-circle"
              />
              <div v-else class="circleUndone" />
            </div>

            <div slot="Feedback" slot-scope="text, record">
              <a-input
                :value="text"
                style="height: 48px"
                :disabled="record.SubmitStatus == 'Waiting'"
                @change="
                  (e) => handleChange(e.target.value, record.key, 'Feedback')
                "
              />
            </div>
            <div slot="Grade" slot-scope="text, record">
              <a-input
                :value="text"
                style="height: 48px"
                :disabled="record.SubmitStatus == 'Waiting'"
                @change="
                  (e) => handleChange(e.target.value, record.key, 'Grade')
                "
              />
            </div>
          </a-table>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import config from '@/config'
import GradeColor from '@/components/app/GradeColor'
import DetailAssignment from '@/components/app/Assignment/DetailAssignment'
const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    // width: '5%',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    // width: '25%',
  },
  {
    title: 'Notes',
    dataIndex: 'Notes',
    // width: '8%',
    scopedSlots: { customRender: 'Notes' },
    className: 'text-center',
  },
  {
    title: 'Submit Status',
    dataIndex: 'SubmitStatus',
    // width: '22%',
    slots: { title: 'customSubmit' },
    scopedSlots: { customRender: 'SubmitStatus' },
  },
  {
    title: 'Feedback',
    dataIndex: 'Feedback',
    // width: '32%',
    scopedSlots: { customRender: 'Feedback' },
  },
  {
    title: 'Grade',
    dataIndex: 'Grade',
    // width: '10%',
    scopedSlots: { customRender: 'Grade' },
  },
]

export default {
  components: {
    GradeColor,
    DetailAssignment,
  },
  data() {
    return {
      isEdit: false,
      visible: false,
      modalAssignmentVisible: false,
      modalVisible: false,
      confirmLoading: false,
      moment,
      columns,
      savedData: [],
      dataDrawer: {},
      sesiGradings: {},
      dataTable: [],
      loadingEditGrading: false,
    }
  },
  methods: {
    toAssignment() {
      this.modalAssignmentVisible = true
    },
    closeModalAssignment() {
      this.modalAssignmentVisible = !this.modalAssignmentVisible
    },
    numberQuestion(index) {
      return ++index
    },
    cekAnswer(soal, jawaban) {
      // console.log(soal, jawaban)
      let correct = 0
      let inCorrect = 0
      let notAnswer = 0
      for (let i = 0; i < soal.length; i++) {
        if (jawaban[i]) {
          if (soal[i].jawaban === jawaban[i]) {
            ++correct
          } else {
            ++inCorrect
          }
        } else {
          ++notAnswer
        }
      }
      return {
        correct,
        inCorrect,
        notAnswer,
      }
    },
    downloadFile(path, filename) {
      const href = `${config.apiUrl}/${path}${filename}`
      window.open(href, '_blank')
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i].Grade)
        if (data[i].Grade) {
          if (isNaN(Number(data[i].Grade))) {
            isInvalid = true
            message = 'Sorry, grade must be numbers.'
            break
          } else if (Number(data[i].Grade) > 100 || Number(data[i].Grade) < 0) {
            isInvalid = true
            message = 'Sorry, grade must be between 0 and 100.'
            break
          }
        } else if ((isNaN(data[i].Grade) || data[i].Grade === '') && data[i].Feedback) {
          isInvalid = true
          message = 'Please fill grade when feedback is filled on the same row.'
          break
        }
      }

      return {
        isInvalid,
        message,
      }
    },
    handleOk(e) {
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
          this.modalVisible = false
        } else {
          const target = newData.map(row => {
            return {
              id: row.idAttendance,
              tugas_nilai: parseInt(row.Grade),
              feedback: row.Feedback,
            }
          })
          this.confirmLoading = true
          this.$store.dispatch('slot/PUT_GRADING', {
            idSesi: this.$route.params.id,
            data: target,
          })
            .then(isSuccess => {
              // console.log('masuk situ')
              setTimeout(() => {
                this.isEdit = false
                this.modalVisible = false
                this.confirmLoading = false
                if (isSuccess) {
                  this.$notification.success({
                    message: 'Successfully update grading',
                  })
                } else {
                  this.$notification.error({
                    message: 'Sorry.',
                    description: 'Grading can\'t be updated right now. Try again later.',
                  })
                }
              }, 2000)
            })
        }
      } else {
        this.isEdit = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.success({
            message: 'Successfully update grading',
          })
          this.modalVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    dueDate(TglDueDate) {
      const hariIni = moment().locale('id')
      const dueDate = moment(TglDueDate)
      let rangeDays = dueDate.diff(hariIni, 'days')
      const rangeWeeks = dueDate.diff(hariIni, 'weeks')
      if (rangeWeeks < 1) {
        if (rangeDays <= 2) {
          this.cardColor = '#FF0102'
          if (rangeDays <= 1) {
            rangeDays = 0
            return rangeDays + ' day'
          }
          return rangeDays + ' more days'
        } else {
          return rangeDays + ' more days'
        }
      } else if (rangeWeeks === 1) {
        return rangeWeeks + ' more week'
      } else if (rangeWeeks > 1) {
        return rangeWeeks + ' more weeks'
      } else {
        return '-'
      }
    },
    showDrawer(id) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => id === item.idMurid)[0]
      this.dataDrawer = target
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    handleChange(value, key, column = 'Feedback') {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.idMurid)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
      // console.log(target)
    },
    save() {
      this.modalVisible = true
    },
    cancel() {
      this.$store.dispatch('slot/FETCH_ASSIGNMENT_GRADING', { idSesi: this.$route.params.id })
        .then(res => {
          this.sesiGradings = res.slot
          this.dataTable = res.dataTable
        })
      this.loadingEditGrading = true
      setTimeout(() => {
        this.isEdit = false
        this.loadingEditGrading = false
      }, 2000)
    },
  },
  created() {
    console.log(this.dataTable)
    this.$store.dispatch('slot/FETCH_ASSIGNMENT_GRADING', { idSesi: this.$route.params.id })
      .then(res => {
        this.sesiGradings = res.slot
        this.dataTable = res.dataTable
      })
  },
  computed: {
    tipeAssignment() {
      if (this.sesiGradings.dataAG.sesis[0].tipe_assignment === 'File Upload') {
        return {
          icon: 'paper-clip',
          type: 'File Upload',
        }
      } else if (this.sesiGradings.dataAG.sesis[0].tipe_assignment === 'Essay') {
        return {
          icon: 'align-left',
          type: 'Essay',
        }
      } else if (this.sesiGradings.dataAG.sesis[0].tipe_assignment === 'Multiple Choice') {
        return {
          icon: 'unordered-list',
          type: 'Multiple Choice',
        }
      } else {
        return {
          icon: 'minus-circle',
          type: 'No Assignment',
        }
      }
    },
  },
}
</script>

<style lang="scss">
.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open {
  .ant-drawer-content-wrapper {
    @media (min-width: 300px) {
      width: 100% !important;
    }
    @media (min-width: 500px) {
      width: 60% !important;
    }
    @media (min-width: 1124px) {
      width: 40% !important;
    }
  }
}
.ant-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 20px;
      // background-color: black;
    }
    .ant-modal-body {
      // padding: 0 24px;
      max-height: 500px;
      overflow-y: auto;
    }
  }
}
.grading {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }

  .circleUndone {
    width: 1em;
    height: 1em;
    border: 2px solid #707788;
    border-radius: 100%;
    margin: 0 auto;
  }
  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }
}
</style>
