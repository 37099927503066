<template>
  <div>
    <div>
      <!-- {{ assignment }} -->
      <p v-html="assignment.tugas_deskripsi" class="text-justify mb-3"></p>
    </div>
    <template v-if="assignment.tipe_assignment === 'Multiple Choice'">
      <div
        class="d-flex flex-column mb-4"
        v-for="(item, idxSoal) in assignment.pg_soals"
        :key="item.id"
      >
        <div class="d-flex">
          <div class="mr-4">
            <p class="font-weight-bold">{{ numberQuestion(idxSoal) }}.</p>
          </div>
          <div class="d-flex flex-column">
            <div>
              <p class="text-justify" v-html="item.soal"></p>
            </div>
            <div
              class="d-flex align-items-center py-2"
              v-for="(option, idxOption) in cekOptions(item)"
              :key="option"
            >
              <template v-if="!isStudentChoice">
                <div class="mr-3">
                  <a-button
                    :type="item.jawaban === choices[idxOption] ? 'primary' : ''"
                    shape="round"
                    size="large"
                    >{{ choices[idxOption] }}</a-button
                  >
                </div>
                <div>
                  <p class="text-justify" v-html="option"></p>
                </div>
              </template>
              <template v-else>
                <div class="mr-3">
                  <a-button
                    :type="studentAnswer[idxSoal] === choices[idxOption] ? 'primary' : ''"
                    shape="round"
                    size="large"
                    >{{ choices[idxOption] }}</a-button
                  >
                </div>
                  <p class="text-justify" v-html="option"></p>
                  <span v-if="item.jawaban === choices[idxOption]" class="text-success d-inline ml-3">(Correct Answer)</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ListMultipleChoice',
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    isStudentChoice: {
      type: Boolean,
    },
    studentAnswer: {
      type: Array,
    },
  },
  data() {
    return {
      choices: ['A', 'B', 'C', 'D', 'E'],
    }
  },
  methods: {
    numberQuestion(index) {
      return ++index
    },
    cekOptions(question) {
      const optionsDefault = ['a', 'b', 'c', 'd', 'e']
      const options = []
      optionsDefault.forEach(el => {
        if (question[`pilihan_${el}`] && question[`pilihan_${el}`] !== '') {
          options.push(question[`pilihan_${el}`])
        }
      })
      return options
    },
  },
}
</script>

<style>
</style>
